import Footer from 'components/footer/Footer';
import Home from 'components/home/Home';
import Loading from 'components/loading/Loading';
import Menu from 'components/menu/Menu';
import Topbar from 'components/topbar/Topbar';
import { useEffect, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';
import './homePage.scss'
import RoadMap from 'components/roadMap/RoadMap';
import Team from 'components/team/Team';
import Mint from 'components/mint/Mint';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Faq from 'components/faq/Faq';
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';
import About from 'components/about/About';
import Benefits from 'components/benifits/Benefits';

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const txTimeoutInMilliseconds = 30000;
export default function HomePage( ) {
    const [isMintLoading, setMintIsLoading] = useState(true);
    const [isGalleryLoading, setGalleryIsLoading] = useState(true);
    const [isTeamLoading, setTeamIsLoading] = useState(true);
    const [sectionHeight, setSectionHeight] = useState("0vh");
    const [loadingHeight, setLoadingHeight] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({query: "screen and (max-width: 450px) and (orientation:portrait)",});
    const isLandOrMobile = useMediaQuery({query: "screen and (max-height: 450px) and (orientation:landscape)",});
    useEffect(() => {
        if (isMintLoading || isGalleryLoading || isTeamLoading) {
            setLoadingHeight(100)
            setSectionHeight("0vh")
        }
        else{
            setLoadingHeight(0)
            setSectionHeight("auto")
        }

        if (isTabletOrMobile) {
        }
    
        if (isLandOrMobile) {
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }
    }, [isMintLoading, isGalleryLoading, isTeamLoading, isTabletOrMobile, isLandOrMobile ]);
    AOS.init();
    window.onload = ()=> {
        setMintIsLoading(false)
        setGalleryIsLoading(false)
        setTeamIsLoading(false)
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
            
          
            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 400, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
          
          });

          setTimeout(() => {
        }, 500);

        
    };
    
    const endpoint = useMemo(() => clusterApiUrl(network), []);

    const wallets = useMemo(
      () => [
        getPhantomWallet(),
        getSolflareWallet(),
        getSlopeWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network }),
      ],
      [],
    );
  
    return (
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletDialogProvider>
            <Toaster
                position="top-center"
                toastOptions={{
                success: { duration: 3000 },
                error: { duration: 3000 },
                }}
            />
            {loadingHeight === 0 &&<Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>}
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <div className='homePage'>
                <div className="loding" style = {{width: "100%", height: loadingHeight + "vh", display: loadingHeight === 0? 'none':'flex'}}>
                    <Loading/>
                </div>
                <div className="sections" style = {{width: "100%", height: sectionHeight}}>
                    <Home/>
                    <About/>
                    <Benefits setIsLoading = {setTeamIsLoading}/>
                    <Mint
                        candyMachineId={candyMachineId}
                        connection={connection}
                        txTimeout={txTimeoutInMilliseconds}
                        rpcHost={rpcHost}
                        setIsLoading = {setMintIsLoading}
                    />
                    <RoadMap/>
                    {/* <Gallery setIsLoading = {setGalleryIsLoading}/> */}
                    <Team setIsLoading = {setTeamIsLoading}/>
                    <Faq/>
                    <Footer />
                </div>
                <img src="assets/backBG.jpg" alt="" className="background" />
            </div>
            </WalletDialogProvider>
        </WalletProvider>
    </ConnectionProvider>
    )
}
