import './roadMap.scss'

export default function RoadMap() {
    const roadMapData = [
        {
            title :'Launch of our social platforms:',
            text:['Hamvengers Instagram', 'Hamvengers Twitter Discord launch','Website Launch Initial Whitelisting for loyal Hamster holders','Initial IG NFTs giveaway','Influencers & Celebrity involvement']
        },
        {
            title :'Hamvengers Launch: Pre-Sale & Mint:',
            text: ['3000 Unique Hamvengers NFTs to be minted by our community. A set number of Hams (~5%) will be held in a community wallet for giveaways and marketing.', 'Amount (TBA).']
        },
        {
            title :'Secondary Markets:',
            text:['Our Hams will be listed on secondary market such as Magic Eden once the minting process is completed.']
        },
        {
            title :'Brand Collaboration and Merchandise:',
            text:['Fashion & Cosmetics Brand','Collaboration (TBA).']
        },
        {
            title :'Further Development:',
            text:['Music Concert – A concert in reality and metaverse that only NFT holders can access (TBA) 3D', 'Collection Launch – Preparation of Phase 2 launch! Owners of Hamvengers will have a free mint/whitelist. (TBA) Animal ADOPT DON’T SHOP Awareness Event (TBA) Mental Health Awareness Charity Events (TBA).']
        },
    ]
    return (
        <div className="roadMap">
            <div className="scroll" id="roadmap"></div>
            <div className="roadMapContent" data-aos="fade-right">
                    <div className="title">
                        <div className="circle">
                        </div>
                        <h1>
                            <span data-aos="fade-up" data-aos-delay ='0'>R</span>
                            <span data-aos="fade-up" data-aos-delay ='100'>o</span>
                            <span data-aos="fade-up" data-aos-delay ='200'>a</span>
                            <span data-aos="fade-up" data-aos-delay ='300'>d</span>
                            <span data-aos="fade-up" data-aos-delay ='400'>m</span>
                            <span data-aos="fade-up" data-aos-delay ='500'>a</span>
                            <span data-aos="fade-up" data-aos-delay ='600'>p</span>
                        </h1>
                        
                    </div>
                <div className="wrapper">
                    <div className="roadLine"></div>
                    {roadMapData.map((d, i)=>(
                        <div className="node" key={i} data-aos="fade-up">
                            <div className="left" >
                                {i% 2=== 1 &&
                                <div className="text" style={{backgroundImage : `url("assets/Path-555.png")`}}>
                                <h2>{d.title}</h2>
                                {d.text.map((e,k)=>(
                                    <p key={k} >{e}</p>
                                ))}
                                </div>
                                }

                            </div>
                            <div className="middle">
                            <div className="inicator">
                                <h2>{i + 1}</h2>
                                <img src="assets/roadmap_node.png" alt="" />
                            </div>
                            </div>
                            <div className="right">
                            {i% 2=== 0 &&
                                <div className="text" style={{backgroundImage : `url("assets/Path-555.png")`}}>
                                <h2>{d.title}</h2>
                                {d.text.map((e,k)=>(
                                    <p key={k} >{e}</p>
                                ))}
                                
                                </div>
                                }
                            </div>
                            
                        </div>
                    ))}
                </div>

                <div className="wrapper_mob">
                    <div className="roadLine"></div>
                    {roadMapData.map((d, i)=>(
                        <div className="node" key={i} data-aos="fade-up">
                            <div className="spot">
                                <img src="assets/roadmap_node.png" alt="" />
                            </div>
                            
                            <div className="text" style={{backgroundImage : `url("assets/Path-555.png")`}}>
                            <h2>{d.title}</h2>
                            {d.text.map((e,k)=>(
                                <p key={k} >{e}</p>
                            ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
