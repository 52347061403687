import './termsHome.scss'

export default function TermsHome() {

    return (
        <div className="termsHome">
            <div className="termsHomeContent">
                <h1>Terms of Service</h1>
                <p>Welcome!</p>
            </div>
        </div>
    )
}
