import './about.scss'

export default function About() {
  return (
    <div  className="about">
      <div className="scroll"  id ="about"></div>
      <div className="aboutContent">
        <h1>
              <span data-aos="fade-up" data-aos-delay ='0'>A</span>
              <span data-aos="fade-up" data-aos-delay ='100'>b</span>
              <span data-aos="fade-up" data-aos-delay ='200'>o</span>
              <span data-aos="fade-up" data-aos-delay ='300'>u</span>
              <span data-aos="fade-up" data-aos-delay ='400'>t</span>
              <span data-aos="fade-up" data-aos-delay ='500'> </span>
              <span data-aos="fade-up" data-aos-delay ='600'>U</span>
              <span data-aos="fade-up" data-aos-delay ='700'>s</span>
            </h1>
       
        <div className="wrapper">
          <div className="left" data-aos="fade-right">
            <img src="assets/ham-tw.gif" alt="" />
            <div className="socialLinks">
                <a href="https://discord.gg/Y6VmU6tdQp" target="_blank" rel="noreferrer"><i className="fab fa-discord"></i></a> 
                <a href="https://www.instagram.com/hamvengersnft" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a> 
                <a href="https://mobile.twitter.com/hamvengersnft" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a> 
            </div>
          </div>
          <div className="right"data-aos="fade-left">
          
            <p><span>Avengers:</span> 3000 Hammies on a mission to fight anyone in their way. The Hamvengers are always down to stand and bang. Currently living in a form of Non-Fungible Tokens on the Solana Blockchain realm.</p>
            <p><span>Who are these Hamvengers?</span></p>

            <p><span>Hamvengers:</span> We are the protectors of the universe. Where peace is needed there’s always gonna be war. A big gang of courageous hamsters decided to form The Hamvengers for this reason.</p>
            <p><span>How good are they at fighting?</span></p>

            <p>They might not be the biggest and most intimidating fighters ever seen. However, the Hamvengers really rely on teamwork, and we know teamwork makes the dream work. The blacksmith hamsters have created certain weapons including swords, hammers, and shields for The Hamvengers to utilize.</p>
            <p><span>How great is this army?</span></p>

            <p>3000 Hammies all with their unique attributes form The Hamvengers. When they unite and  assemble, they can stop all evil forces coming at them.</p>

          </div>
        </div>
    </div>
  </div>

  );
  
}


