import './footer.scss'
import {HashLink} from 'react-router-hash-link'
export default function Footer() {
    return (
        <div className="footer" style={{backgroundImage : `url("assets/footerbg.png")`}}>
            
            <div className="footerContent">
                <HashLink to="/#home" smooth ><img src="assets/logo.png" alt="" /></HashLink>
                <p>Follow our community pages/rooms on social media platforms below and be the first to know about the exciting things to come to our Hamvengers family</p>
                <div className="socialLinks">
                    <a href="https://discord.gg/Y6VmU6tdQp" target="_blank" rel="noreferrer"><i className="fab fa-discord"></i></a> 
                    <a href="https://www.instagram.com/hamvengersnft" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a> 
                    <a href="https://mobile.twitter.com/hamvengersnft" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a> 
                   
                    
                </div>
            </div>
        </div>
    )
}
