import { useEffect, useState, useMemo, useCallback } from 'react';
import * as anchor from '@project-serum/anchor';
import './mint.scss'
import styled from 'styled-components';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from 'utils/candy-machine';
import { AlertState, toDate, formatNumber, getAtaForMint } from 'utils/utils';
import { MintCountdown } from './MintCountdown';
import { MintButton } from './MintButton';
import { GatewayProvider } from '@civic/solana-gateway-react';
import whitelist from "./whitelist.json";

export const isWhitelisted = (publickey: string) => {
  for (var i = 0; i < whitelist.length; i++) {
    if (whitelist[i].toLowerCase() === publickey.toLowerCase())
      return true;
  }
  return false;
}

const MintContainer = styled.div``; // add your owns styles here
export interface MintProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  setIsLoading(value:boolean): void;
}

const Mint = (props: MintProps) => {
  const loadHandle = ()=>{
    props.setIsLoading(false)

  }
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });
  const [isActive, setIsActive] = useState(false);
  const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [discountPrice, setDiscountPrice] = useState<anchor.BN>();

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    if (props.candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection,
        );
        let active =
          cndy?.state.goLiveDate?.toNumber() < new Date().getTime() / 1000;
        let presale = false;
        // whitelist mint?
        if (cndy?.state.whitelistMintSettings) {
          // is it a presale mint?
          if (
            cndy.state.whitelistMintSettings.presale &&
            (!cndy.state.goLiveDate ||
              cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
          ) {
            presale = true;
          }
          // is there a discount?
          if (cndy.state.whitelistMintSettings.discountPrice) {
            setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
          } else {
            setDiscountPrice(undefined);
            // when presale=false and discountPrice=null, mint is restricted
            // to whitelist users only
            if (!cndy.state.whitelistMintSettings.presale) {
              cndy.state.isWhitelistOnly = true;
            }
          }
          // retrieves the whitelist token
          const mint = new anchor.web3.PublicKey(
            cndy.state.whitelistMintSettings.mint,
          );
          const token = (await getAtaForMint(mint, anchorWallet.publicKey))[0];

          try {
            const balance = await props.connection.getTokenAccountBalance(
              token,
            );
            let valid = parseInt(balance.value.amount) > 0;
            // only whitelist the user if the balance > 0
            setIsWhitelistUser(valid);
            active = (presale && valid) || active;
          } catch (e) {
            setIsWhitelistUser(false);
            // no whitelist user, no mint
            if (cndy.state.isWhitelistOnly) {
              active = false;
            }
            console.log('There was a problem fetching whitelist token balance');
            console.log(e);
          }
        }
        // datetime to stop the mint?
        if (cndy?.state.endSettings?.endSettingType.date) {
          setEndDate(toDate(cndy.state.endSettings.number));
          if (
            cndy.state.endSettings.number.toNumber() <
            new Date().getTime() / 1000
          ) {
            active = false;
          }
        }
        // amount to stop the mint?
        if (cndy?.state.endSettings?.endSettingType.amount) {
          let limit = Math.min(
            cndy.state.endSettings.number.toNumber(),
            cndy.state.itemsAvailable,
          );
          if (cndy.state.itemsRedeemed < limit) {
            setItemsRemaining(limit - cndy.state.itemsRedeemed);
          } else {
            setItemsRemaining(0);
            cndy.state.isSoldOut = true;
          }
        } else {
          setItemsRemaining(cndy.state.itemsRemaining);
        }

        if (cndy.state.isSoldOut) {
          active = false;
        }

        setIsActive((cndy.state.isActive = active));
        setIsPresale((cndy.state.isPresale = presale));
        setCandyMachine(cndy);
      } catch (e) {
        console.log('There was a problem fetching Candy Machine state');
        console.log(e);
      }
    }
  }, [anchorWallet, props.candyMachineId, props.connection]);

  const onMint = async () => {
    try {
      setIsUserMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        if (
          !isWhitelisted(wallet.publicKey.toString()) &&
          Date.now() < 1648303200000
        ) {
          // (GMT): Saturday, March 26, 2022 2:00:00 PM
          setAlertState({
            open: true,
            message: "Please wait public launch time!",
            severity: "error",
          });

          return;
        }

        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            true,
          );
        }

        if (status && !status.err) {
          // manual update since the refresh might not detect
          // the change immediately
          let remaining = itemsRemaining! - 1;
          setItemsRemaining(remaining);
          setIsActive((candyMachine.state.isActive = remaining > 0));
          candyMachine.state.isSoldOut = remaining === 0;
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          });
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
        }
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });
      // updates the candy machine state to reflect the lastest
      // information on chain
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  const toggleMintButton = () => {
    let active = !isActive || isPresale;

    if (active) {
      if (candyMachine!.state.isWhitelistOnly && !isWhitelistUser) {
        active = false;
      }
      if (endDate && Date.now() >= endDate.getTime()) {
        active = false;
      }
    }

    if (
      isPresale &&
      candyMachine!.state.goLiveDate &&
      candyMachine!.state.goLiveDate.toNumber() <= new Date().getTime() / 1000
    ) {
      setIsPresale((candyMachine!.state.isPresale = false));
    }

    setIsActive((candyMachine!.state.isActive = active));
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  return (
        <div className="mint"  style={{backgroundImage : `url("assets/footerbg.png")`}}>
            <div className="scroll" id='mint'> </div>
                <div className="mintContent">
                  <h1>
                      <span data-aos="fade-up" data-aos-delay ='0'>M</span>
                      <span data-aos="fade-up" data-aos-delay ='100'>i</span>
                      <span data-aos="fade-up" data-aos-delay ='200'>n</span>
                      <span data-aos="fade-up" data-aos-delay ='300'>t</span>
                  </h1>
                    <div className="mintWrapper" >
                        <div className="left" data-aos="fade-left">
                            
                            <h2>Mint Your Hamvengers</h2>
                            
                          <div className="mintPart">

                              <>
                                {candyMachine && (
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    wrap="nowrap"
                                  >
                                    <Grid item xs={3}>
                                      <Typography variant="body2" color="textSecondary">
                                        Remaining
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        color="textPrimary"
                                        style={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {`${itemsRemaining}`}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body2" color="textSecondary">
                                        {isWhitelistUser && discountPrice
                                          ? 'Discount Price'
                                          : 'Price'}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        color="textPrimary"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        {isWhitelistUser && discountPrice
                                          ? `◎ ${formatNumber.asNumber(discountPrice)}`
                                          : `◎ ${formatNumber.asNumber(
                                              candyMachine.state.price,
                                            )}`}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                      {isActive && endDate && Date.now() < endDate.getTime() ? (
                                        <>
                                          <MintCountdown
                                            key="endSettings"
                                            date={getCountdownDate(candyMachine)}
                                            style={{ justifyContent: 'flex-end' }}
                                            status="COMPLETED"
                                            onComplete={toggleMintButton}
                                          />
                                          <Typography
                                            variant="caption"
                                            align="center"
                                            display="block"
                                            style={{ fontWeight: 'bold' }}
                                          >
                                            TO END OF MINT
                                          </Typography>
                                        </>
                                      ) : (
                                        <>
                                          <MintCountdown
                                            key="goLive"
                                            date={getCountdownDate(candyMachine)}
                                            style={{ justifyContent: 'flex-end' }}
                                            status={
                                              candyMachine?.state?.isSoldOut ||
                                              (endDate && Date.now() > endDate.getTime())
                                                ? 'COMPLETED'
                                                : isPresale
                                                ? 'PRESALE'
                                                : 'LIVE'
                                            }
                                            onComplete={toggleMintButton}
                                          />
                                          {isPresale &&
                                            candyMachine.state.goLiveDate &&
                                            candyMachine.state.goLiveDate.toNumber() >
                                              new Date().getTime() / 1000 && (
                                              <Typography
                                                variant="caption"
                                                align="center"
                                                display="block"
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                UNTIL PUBLIC MINT
                                              </Typography>
                                            )}
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                )}
                                <MintContainer>
                                  {candyMachine?.state.isActive &&
                                  candyMachine?.state.gatekeeper &&
                                  wallet.publicKey &&
                                  wallet.signTransaction ? (
                                    <GatewayProvider
                                      wallet={{
                                        publicKey:
                                          wallet.publicKey ||
                                          new PublicKey(CANDY_MACHINE_PROGRAM),
                                        //@ts-ignore
                                        signTransaction: wallet.signTransaction,
                                      }}
                                      gatekeeperNetwork={
                                        candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                                      }
                                      clusterUrl={rpcUrl}
                                      options={{ autoShowModal: false }}
                                    >
                                      <MintButton
                                        candyMachine={candyMachine}
                                        isMinting={isUserMinting}
                                        onMint={onMint}
                                        isActive={isActive || (isPresale && isWhitelistUser)}
                                      />
                                    </GatewayProvider>
                                  ) : (
                                    <MintButton
                                      candyMachine={candyMachine}
                                      isMinting={isUserMinting}
                                      onMint={onMint}
                                      isActive={isActive || (isPresale && isWhitelistUser)}
                                    />
                                  )}
                                </MintContainer>
                              </>

                          </div>


                          <h3> 0.68 SOL on Presale</h3>
                          <h3>0.88 SOL on Public minting.</h3>

                        </div>                       
                        <div className="right">
                        <div className="leftImg" data-aos="zoom-in"  data-aos-delay ='300'>
                            <img src="assets/Sample_hamvenger.png"  alt="" onLoad={loadHandle} />
                            </div>
                        </div>
                        
                </div> 
            </div>
            <Snackbar
              open={alertState.open}
              autoHideDuration={6000}
              onClose={() => setAlertState({ ...alertState, open: false })}
            >
              <Alert
                onClose={() => setAlertState({ ...alertState, open: false })}
                severity={alertState.severity}
              >
                {alertState.message}
              </Alert>
            </Snackbar>
        </div>
    )
}
const getCountdownDate = (
  candyMachine: CandyMachineAccount,
): Date | undefined => {
  if (
    candyMachine.state.isActive &&
    candyMachine.state.endSettings?.endSettingType.date
  ) {
    return toDate(candyMachine.state.endSettings.number);
  }

  return toDate(
    candyMachine.state.goLiveDate
      ? candyMachine.state.goLiveDate
      : candyMachine.state.isPresale
      ? new anchor.BN(new Date().getTime() / 1000)
      : undefined,
  );
};
export default Mint;