import Footer from 'components/footer/Footer';
import Loading from 'components/loading/Loading';
import Menu from 'components/menu/Menu';
import TermsHome from 'components/termsHome/TermsHome';
import Topbar from 'components/topbar/Topbar';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';
import './termsPage.scss'
export default function TermsPage( ) {
    const [isLoading, setIsLoading] = useState(true);
    const [sectionHeight, setSectionHeight] = useState("0vh");
    const [loadingHeight, setLoadingHeight] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({query: "screen and (max-width: 768px) and (orientation:portrait)",});
    const isLandOrMobile = useMediaQuery({query: "screen and (max-height: 768px) and (orientation:landscape)",});
    useEffect(() => {
        setIsLoading(false)
        if (isLoading) {
            setLoadingHeight(100)
            setSectionHeight("0vh")
        }
        else{
            setLoadingHeight(0)
            setSectionHeight("auto")
        }

        if (isTabletOrMobile) {
        }
    
        if (isLandOrMobile) {
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }
    }, [isLoading, isTabletOrMobile, isLandOrMobile ]);
    return (
        <>
            <Toaster
                position="top-center"
                toastOptions={{
                success: { duration: 3000 },
                error: { duration: 3000 },
                }}
            />
            {loadingHeight === 0 &&<Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>}
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <div className='termsPage'>
                <div className="loding" style = {{width: "100%", height: loadingHeight + "vh", display: loadingHeight === 0? 'none':'flex'}}>
                    <Loading/>
                </div>
                <div className="sections" style = {{width: "100%", height: sectionHeight}}>
                    <TermsHome/>
                    <Footer />
                </div>
            </div>
        </>
    )
}
