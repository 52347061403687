import './app.scss';
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import HomePage from 'pages/homePage/HomePage';
import TermsPage from 'pages/termsPage/TermsPage';
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/terms-of-service" component={TermsPage} />
      </Switch>
    </Router>
  );
}
export default App;
