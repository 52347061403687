import { useState } from 'react';
import './team.scss';
type LoadingType = {
    setIsLoading(value:boolean): void;
};
export default function Team({setIsLoading}:LoadingType) {
    const teamData = [
        {
            img : 'assets/12.png',
            title:'Founder',
            name:'BDV'
        },
        {
            img : 'assets/232.png',
            title:'CEO',
            name:'Tank'
        },
        {
            img : 'assets/156.png',
            title:'Community-Venger',
            name:'Jo'
        },
        {
            img : 'assets/13.png',
            title:'Art-venger',
            name:'prince'
        },
        {
            img : 'assets/7.png',
            title:'Marketing-venger',
            name:'Shib'
        },
        {
            img : 'assets/161.png',
            title:'Dev-venger',
            name:'Tank'
        },
        {
            img : 'assets/8.png',
            title:'Dev-venger',
            name:'Corn'
        },
        {
            img : 'assets/157.png',
            title:'Moderator',
            name:'Gold-f'
        },
        
    ]

    const [imgCount, setImgCount] = useState(0);
    const loadHandle = ()=>{
        setImgCount(imgCount + 1)
        if (imgCount>=11){
            setIsLoading(false)
        }
      }
    return (
        <div className="team">
            <div className="scroll"  id="team"></div>
            <div className="teamContent" >
            <h1>
                    <span data-aos="fade-up" data-aos-delay ='100'>T</span>
                    <span data-aos="fade-up" data-aos-delay ='200'>e</span>
                    <span data-aos="fade-up" data-aos-delay ='300'>a</span>
                    <span data-aos="fade-up" data-aos-delay ='400'>M</span>
                </h1>
                <div className="wrapper"  data-aos="fade-up">
                    {teamData.map((d, i)=>(
                        <div className="item" key={i} >
                        <div className="itemContent">
                        <img src={d.img} alt=""  onLoad={loadHandle}/>
                        </div>
                        <h3>{d.title}</h3>
                        <p>{d.name}</p>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
