import { useState } from "react";
import Expand from "react-expand-animated";
import "./faq.scss"
export default function Faq() {

    const faqData = [
        {
            id:0,
            quetion : "How many Hamvengers are there?",
            answer: ['- 3000 total supply of Hamvengers', '- ~10-20% minted for marketing and project development'],
        },
        
        // {
        //     id:1,
        //     quetion : "How much does an Hamvenger cost?",
        //     answer : ["- 0.68 SOL on Presale",'- 0.88 SOL on Public minting.'],
        // },
        {
            id:1,
            quetion : "Presale",
            answer : ["-899 whitelist address","- join discord and follow Hamvengers IG to get invited"],
        },
        {
            id:2,
            quetion : "When does public minting start?",
            answer : ["Presale Mint: Date for whitelist VIP only!","Public Mint: March 26 10PM HKT ( 2PM UTC)"],
        },
        // {
        //     id:4,
        //     quetion : "How do I mint an Hamvenger?",
        //     answer : ["Public minting will be open on ,","- Have Phantom installed on your device to connect wallet","- Click mint Button","- Confirm transaction by following instruction on Phantom."],
        // },
        {
            id:3,
            quetion : "Public Sale",
            answer : ["You can purchase as many Hamvengers as you like, but limited to 1 purchase per mint."],
        }
    ]
    const [faqId, setFaqId] = useState(-1);
    const handleClick = (id:number)=>{
        if (faqId !== -1){
            if(faqId === id){
                setFaqId(-1)
            }
            else{
                setFaqId(id)
            }
        }
        else{
            setFaqId(id)
        }

    }
    const styles = {
        open: { width: "100%" },
        close: { width: "100%" }
    };
    const transitions = ["height", "opacity", "background"];
    return (
        <div className="faq" >
            <div className="scroll"  id="faq"></div>
            <div className="faqContent">
                <h1>
                    <span data-aos="fade-up" data-aos-delay ='100'>F</span>
                    <span data-aos="fade-up" data-aos-delay ='200'>a</span>
                    <span data-aos="fade-up" data-aos-delay ='300'>q</span>
                </h1>
                <div className="faqWrapper">
                    {faqData.map((d)=>(
                        <div  className="line" 
                            data-aos="fade-right" key = {d.id} 
                            onClick={()=>{handleClick(d.id)}}
                           >
                            <div className="question">
                                <p >{d.quetion}</p>
                                {d.id === faqId? <i className="show fas fa-minus"/> 
                                : <i className="show fas fas fa-plus"/>}

                            </div>
                            <Expand
                                open={faqId === d.id}
                                duration={300}
                                styles={styles}
                                transitions={transitions}
                            >
                                {d.answer.map((e, k)=>(
                                    <p className="answer" key={(k)}>{e}</p>
                                ))}
                                
                            </Expand>
                        </div>
                    ))}
                     
                </div>
            </div>
        </div>
    )
}
