import "./menu.scss"
import {HashLink} from 'react-router-hash-link'
type MenuType = {
    menuOpen : boolean;
    setMenuOpen(flag:boolean):void;
};

export default function Menu({menuOpen, setMenuOpen}:MenuType) {
    return (
        <div className={"sidebar " + (menuOpen && "active")}>
            <ul>
                <li onClick = {()=> setMenuOpen(false)} className = {"color2 menuItem2 " + (menuOpen && "active")}>
                <HashLink to="/#about" smooth>About Us</HashLink>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"color5 menuItem3 " + (menuOpen && "active")}>
                <HashLink to="/#benefits" smooth>Benefits</HashLink>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"color3 menuItem5 " + (menuOpen && "active")}>
                <HashLink to="/#mint" smooth>Mint</HashLink>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"color4 menuItem4 " + (menuOpen && "active")}>
                <HashLink to="/#roadmap" smooth>RoadMap</HashLink>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"color5 menuItem6 " + (menuOpen && "active")}>
                <HashLink to="/#team" smooth>Team</HashLink>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"color5 menuItem6 " + (menuOpen && "active")}>
                <HashLink to="/#faq" smooth>Faq</HashLink>
                </li>
            </ul>
        </div>
    )
}

