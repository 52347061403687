import {HashLink} from 'react-router-hash-link'
import './topbar.scss'
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";
type MenuType = {
    menuOpen?: boolean;
    setMenuOpen(flag: boolean): void;
};

export default function Topbar({ menuOpen, setMenuOpen }: MenuType) {

    return (
        <div className="topbar">
            <div className="logo">
                <HashLink to="/#home" ><img src="assets/logo.png" alt="" /></HashLink>
            </div>
            <div className="navList">
                <ul>
                    <li><HashLink to="/#about" smooth>About Us</HashLink></li>
                    <li><HashLink to="/#benefits" smooth>Benefits</HashLink></li>
                    <li><HashLink to="/#mint" smooth>Mint</HashLink></li>
                    <li><HashLink to="/#roadmap" smooth>RoadMap</HashLink></li>
                    <li><HashLink to="/#team" smooth>Team</HashLink></li>
                    <li><HashLink to="/#faq" smooth>FAQ</HashLink></li>
                </ul>
            </div>

            <div className="btns">
            <WalletMultiButton className="connect-btn" />
                <div className="socialLinks">
                    {/* <a href="/" target="_blank" rel="noreferrer"><img src="assets/sol.png" alt="" /></a>  */}
                    <a href="https://discord.gg/Y6VmU6tdQp" target="_blank" rel="noreferrer"><i className="fab fa-discord"></i></a> 
                    <a href="https://www.instagram.com/hamvengersnft" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a> 
                    <a href="https://mobile.twitter.com/hamvengersnft" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a> 
                    
                </div>
            </div>
            
            <div className={(menuOpen ? "hamburger active" : "hamburger")} onClick={() => setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
        </div>
    )
}
