import { useState } from 'react';
import './benefits.scss';
type LoadingType = {
    setIsLoading(value:boolean): void;
};
export default function Benefits({setIsLoading}:LoadingType) {
    const [imgCount, setImgCount] = useState(0);
    const loadHandle = ()=>{
        setImgCount(imgCount + 1)
        if (imgCount>=11){
            setIsLoading(false)
        }
      }
    return (
        <div className="benefits">
            <div className="scroll"  id="benefits"></div>
            <div className="benefitsContent" >
                <h1>
                    <span data-aos="fade-up" data-aos-delay ='0'>B</span>
                    <span data-aos="fade-up" data-aos-delay ='100'>e</span>
                    <span data-aos="fade-up" data-aos-delay ='200'>n</span>
                    <span data-aos="fade-up" data-aos-delay ='300'>e</span>
                    <span data-aos="fade-up" data-aos-delay ='400'>f</span>
                    <span data-aos="fade-up" data-aos-delay ='500'>i</span>
                    <span data-aos="fade-up" data-aos-delay ='600'>t</span>
                    <span data-aos="fade-up" data-aos-delay ='700'>s</span>
                </h1>
                <div className="wrapper"  data-aos="fade-up">
                    <div className="item" >
                        <img src="assets/105.png" alt="" className='mainImg'/>
                        <div className="itemContent">
                        <img src='assets/whiteList.png' alt=""  onLoad={loadHandle}/>
                        <p>Hamvengers holders will get access to the community and whitelist to future projects.</p>
                        </div>
                    </div>
                    
                    <div className="item" >
                        <img src="assets/229.png" alt="" className='mainImg'/>
                        <div className="itemContent">
                        <img src='assets/charity.png' alt=""  onLoad={loadHandle}/>
                        <p>Charity organisations support and Funding.</p>
                        </div>
                    </div>

                    <div className="item" >
                        <img src="assets/1194.png" alt="" className='mainImg'/>
                        <div className="itemContent">
                        <img src='assets/eventLogo.png' alt=""  onLoad={loadHandle}/>
                        <p>Exclusive community virtual events (only for holders.)</p>
                        </div>
                    </div>
                    <div className="item" >
                        <img src="assets/2935.png" alt="" className='mainImg'/>
                        <div className="itemContent">
                        <img src='assets/icon-256x256.png' alt=""  onLoad={loadHandle}/>
                        <p>Giveaways in our various Social media community groups.</p>
                        </div>
                    </div>

                    <div className="item" >
                        <img src="assets/1401.png" alt="" className='mainImg'/>
                        <div className="itemContent">
                        <img src='assets/beautiful-female-face-silhouette-profile-260nw-599614880.png' alt=""  onLoad={loadHandle}/>
                        <p>Future collaboration and introduction of Fashion and Cosmetics Brands.</p>
                        </div>
                    </div>

                    <div className="item" >
                        <img src="assets/2761.png" alt="" className='mainImg'/>
                        <div className="itemContent">
                        <img src='assets/1f1c30f7e55a56b5aaf5260093e71340.png' alt=""  onLoad={loadHandle}/>
                        <p>And much more...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
