import './home.scss'
export default function Home() {

    return (
        <div className="home" id = "home" >
            <div className="homeContent">
                <div className="wrapper"  data-aos="fade-up">
                    <h1>JOIN THE HAMVENGERS</h1>
                    <p><span>Hamvengers’s</span> team will be HOLDING 500 NFTs ourselves, which would massively decrease the supplies of hams in the secondary markets and would in turn benefit Hamster holders like yourself! With such limited supply, it would make it much easier for our team and NFT whales to sweep the floor and ensure appreciation in the value of our Hammies.</p>
                    <div className="joinBtns">
                        <a href="https://discord.gg/Y6VmU6tdQp" target="_blank" rel="noreferrer" className="discord">Visit Discord</a>
                        <a href="/" target="_blank" rel="noreferrer" className="viewMagic">View on Magic Eden</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
